import React from "react";
import {
	H1,
	AllOrNone,
	DataObserveKey,
	VisualComponent,
	FocusableComponent,
} from "@siteimprove/fancylib";
import { BreadcrumbProps, Breadcrumbs } from "../../navigation/breadcrumbs/breadcrumbs";
import * as scss from "./base-layout.scss";

export type BaseLayoutProps = {
	/** elements to populate the base layout */
	children: React.ReactNode;
	/** Breadcrumbs to be displayed for navigation  */
	breadcrumbs?: BreadcrumbProps;
	/** ID for the main component of base-layout */
	id: string;
	/** optional extra properties for the page header */
	pageHeaderProps?: PageHeaderProps;
} & AllOrNone<{ "aria-label": string; pickers: React.ReactNode }>;

export function BaseLayout(props: BaseLayoutProps): JSX.Element {
	const { children, pageHeaderProps, pickers, "aria-label": ariaLabel, breadcrumbs, id } = props;

	return (
		<>
			{pickers && ariaLabel && (
				<div
					role="region"
					aria-label={ariaLabel}
					data-component="picker-bar"
					className={scss.pickerBar}
				>
					{pickers}
				</div>
			)}
			<main data-component="base-layout" id={id} className={scss.main}>
				{breadcrumbs && <Breadcrumbs {...breadcrumbs} />}
				{pageHeaderProps && <PageHeader {...pageHeaderProps} />}
				{children}
			</main>
		</>
	);
}

type PageHeaderProps = {
	/** page title  */
	title?: React.ReactNode;
	extraActions?: React.ReactNode;
	/**
	 * Used as a prefix (e.g. product name) for data observe keys
	 */
	observeKeyPrefix?: string;
} & DataObserveKey &
	FocusableComponent &
	VisualComponent;

function PageHeader(props: PageHeaderProps): JSX.Element {
	const { title, extraActions, observeKeyPrefix, ...rest } = props;

	return (
		<div {...rest} data-component="page-header" className={scss.pageHeader}>
			{title !== undefined ? typeof title === "string" ? <H1>{title}</H1> : title : <div />}
			{/* Empty div to satisfy flex */}
			<div className={`fancy-PageHeaderButtons ${scss.pageHeaderButtons}`}>
				{extraActions}
				<div />
			</div>
		</div>
	);
}
